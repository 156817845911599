import FilterIcons from "../_helpers/Icons/filterIcons";
import HeaderIcons from "../_helpers/Icons/headerIcons";

export const DT_ND = "ND";
export const DT_LGD = "LGD";
export const DT_RD = "RD";

export const monthsInShort = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

export const monthsInLong = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const daysInShort = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const colorType = [
  {
    name: "Colorless",
    category: [
      {
        type: "D-F",
      },
    ],
  },
  {
    name: "Near Colorless",
    category: [
      {
        type: "G",
        bg: "#FFFFFB",
      },
      {
        type: "H",
        bg: "#FFFFF7",
      },
      {
        type: "I",
        bg: "#FFFFF3",
      },
      {
        type: "J",
        bg: "#FEFFF1",
      },
    ],
  },
  {
    name: "Champagne",
    category: [
      {
        type: "K",
        bg: "#FFFEEA",
      },
      {
        type: "L",
        bg: "#FAFAE0",
      },
      {
        type: "M",
        bg: "#FDFDD9",
      },
    ],
  },
];

export const diamondVideoContainerHeight = "calc(100vh - 215px)";

export const metal = ["WG", "YG", "RG", "PL"];

export const ringCuts = [
  "Round",
  "Oval",
  "Cushion",
  "Elongated Cushion",
  "Emerald",
  "Asscher",
  "Pear",
  "Rose Cut",
  "Marquise",
  "Trillion",
  "Radiant",
  "Princess",
];

export const shape = [
  "Round",
  "Cushion",
  "Elongated Cushion",
  "Asscher",
  "Rose Cut",
  "Trillion",
  "Old Mine",
  "Princess",
  "Oval",
  "Emerald",
  "Pear",
  "Marquise",
  "Old European",
  "Radiant",
];

export const EngagementRingsCardData = [
  {
    heading: "Design Something You",
    subheading:
      "Take a short quiz, and receive a free sketch of your dream ring.",
    ctaText: "Take the Quiz",
    ctaLink: "https://frankdarling.typeform.com/to/SwTTd2",
    position: 5,
  },
  {
    heading: "Engagement Rings Built to Last",
    subheading: "Low profile engagement rings you can wear anywhere.",
    ctaText: "",
    ctaLink: "",
    position: 27,
  },
  {
    heading: "Custom Engagement Rings Made Simple",
    subheading: "Uncomplicating custom design, one engagement ring at a time.",
    ctaText: "",
    ctaLink: "",
    position: 58,
  },
  {
    heading: "All Custom, Always",
    subheading:
      "With our made to order process, all of our rings are custom engagement rings.",
    ctaText: "",
    ctaLink: "",
    position: 105,
  },
  {
    heading: "Built for the Mold-Breakers",
    subheading:
      "Our unique engagement rings are built with your individual style in mind.",
    ctaText: "",
    ctaLink: "",
    position: 155,
  },
  {
    heading: "Engagement Rings for All",
    subheading:
      "We create bespoke engagement rings for every taste and budget in mind.",
    ctaText: "",
    ctaLink: "",
    position: 167,
  },
];

export const WeddingRingsCardData = [
  {
    heading: "Design Something You",
    subheading:
      "Take a short quiz, and receive a free sketch of your dream ring.",
    ctaText: "Take the Quiz",
    ctaLink: "https://frankdarling.typeform.com/to/SwTTd2",
    position: 5,
  },
  {
    heading: "Wedding Rings With a Twist",
    subheading: "We create bespoke wedding rings that reflect your love story.",
    ctaText: "",
    ctaLink: "",
    position: 27,
  },
  {
    heading: "Bespoke in Your Budget",
    subheading:
      "Affordable wedding rings aren’t out of reach with our modern collection.",
    ctaText: "",
    ctaLink: "",
    position: 58,
  },
  {
    heading: "Custom Made Classic",
    subheading:
      "Our custom wedding rings are created for you, and meant to last forever.",
    ctaText: "",
    ctaLink: "",
    position: 105,
  },
  {
    heading: "Wedding (Ring) Bells",
    subheading:
      "From simple to show-stopping, our wedding rings are designed for every style.",
    ctaText: "",
    ctaLink: "",
    position: 155,
  },
  {
    heading: "Ring it Up",
    subheading:
      "Our wedding rings highlight matched pairs, individual styles and everything in between.",
    ctaText: "",
    ctaLink: "",
    position: 167,
  },
];

export const ringFilterLinks = [
  {
    title: "Shape",
    links: [
      { title: "Oval", Icon: HeaderIcons.OvalOutline },
      { title: "Emerald", Icon: HeaderIcons.EmeraldOutline },
      { title: "Round", Icon: HeaderIcons.RoundOutline },
      { title: "Radiant", Icon: HeaderIcons.RadiantOutline },
      { title: "Pear", Icon: HeaderIcons.PearOutline },
      { title: "Asscher", Icon: HeaderIcons.AsscherOutline },
      {
        title: "Marquise",
        Icon: HeaderIcons.MarquiseOutline,
      },
      { title: "Cushion", Icon: HeaderIcons.CushionOutline },
      {
        title: "Elongated Cushion",
        Icon: HeaderIcons.ElongatedCushion,
      },
      {
        title: "Princess",
        Icon: HeaderIcons.PrincessOutline,
      },
      {
        title: "Old European",
        Icon: HeaderIcons.OldEuropeanOutline,
      },
      {
        title: "Old Mine",
        Icon: FilterIcons.OldMineOutline,
      },
      {
        title: "Rose Cut",
        Icon: HeaderIcons.RoseCutDiamond,
      },
      {
        title: "Trillion",
        Icon: HeaderIcons.TrillionOutline,
      },
    ],
  },
  {
    title: "Profile",
    links: [
      {
        title: "Medium Profile",
        Icon: FilterIcons.UltraLowProfileIcon,
      },
      {
        title: "Low Profile",
        Icon: FilterIcons.LowProfileIcon,
      },
      {
        title: "Ultra Low Profile",
        Icon: FilterIcons.MediumProfileIcon,
      },
    ],
  },
  {
    title: "Style",
    links: [
      { title: "Solitaire" },
      { title: "Bezel" },
      { title: "Three Stone" },
      { title: "Hidden Halo" },
      { title: "Cathedral" },
      { title: "Diamond Details" },
      { title: "Half Bezel" },
      { title: "East West" },
      { title: "Two Tone" },
      { title: "Pave" },
      { title: "Five Stone" },
      { title: "Split Shank" },
      { title: "Halo" },
      { title: "Vintage Inspired" },
      { title: "Cluster" },
    ],
  },
  {
    title: "Collection",
    links: [
      { title: "The Clutch" },
      { title: "The Plunge" },
      { title: "The Plutch" },
      { title: "Harper" },
      { title: "The Gumdrops" },
      { title: "The Breezy" },
      { title: "The Scoop" },
      { title: "The Royale" },
      { title: "The Link" },
      { title: "The Golden Bathtub" },
      { title: "The Nouveau" },
    ],
  },
  {
    title: "Stackable",
    links: [
      {
        title: "Doesn't Stack",
        Icon: FilterIcons.NoStackIcon,
      },
      {
        title: "Small Gap",
        value: "Stacks with a Small Gap",
        Icon: FilterIcons.StackIcon,
      },
      {
        title: "Stacks Flush",
        Icon: FilterIcons.FlushStackIcon,
      },
    ],
  },
  {
    title: "Metal",
    links: [
      { title: "Yellow Gold" },
      { title: "White Gold" },
      { title: "Rose Gold" },
      { title: "Platinum" },
    ],
  },
  {
    title: "Try at home",
    links: ["Available"],
  },
  {
    title: "Sort",
    links: [
      { title: "Recommended" },
      { title: "Best Selling" },
      { title: "Newest" },
      { title: "Price: High to Low" },
      { title: "Price: Low to High" },
    ],
  },
];

export const necklaceFilterLinks = [
  {
    title: "Shape",
    links: [
      { title: "Round", Icon: HeaderIcons.RoundOutline },
      { title: "Oval", Icon: HeaderIcons.OvalOutline },
      { title: "Emerald", Icon: HeaderIcons.EmeraldOutline },
      { title: "Asscher", Icon: HeaderIcons.AsscherOutline },
      { title: "Pear", Icon: HeaderIcons.PearOutline },
      {
        title: "Marquise",
        Icon: HeaderIcons.MarquiseOutline,
      },
      {
        title: "Princess",
        Icon: HeaderIcons.PrincessOutline,
      },
    ],
  },
  {
    title: "Style",
    links: [{ title: "Prong" }, { title: "Bezel" }],
  },
  {
    title: "Collection",
    links: [
      { title: "The OG" },
      { title: "Radiator" },
      { title: "The Lowrider" },
    ],
  },
  {
    title: "Metal",
    links: [
      { title: "Yellow Gold" },
      { title: "White Gold" },
      { title: "Rose Gold" },
      { title: "Platinum" },
    ],
  },
  {
    title: "Sort",
    links: [
      { title: "Recommended" },
      { title: "Best Selling" },
      { title: "Newest" },
      { title: "Price: High to Low" },
      { title: "Price: Low to High" },
    ],
  },
];

export const studsFilterLinks = [
  {
    title: "Shape",
    links: [
      { title: "Round", Icon: HeaderIcons.RoundOutline },
      { title: "Oval", Icon: HeaderIcons.OvalOutline },
      { title: "Emerald", Icon: HeaderIcons.EmeraldOutline },
      { title: "Asscher", Icon: HeaderIcons.AsscherOutline },
      { title: "Pear", Icon: HeaderIcons.PearOutline },
      {
        title: "Marquise",
        Icon: HeaderIcons.MarquiseOutline,
      },
      {
        title: "Princess",
        Icon: HeaderIcons.PrincessOutline,
      },
    ],
  },
  {
    title: "Style",
    links: [{ title: "Prong" }, { title: "Bezel" }, { title: "Half Bezel" }],
  },
  {
    title: "Collection",
    links: [
      { title: "The OG" },
      { title: "The Royale" },
      { title: "The Clutch" },
      { title: "The Lowrider" },
      { title: "Single Scoop" },
      { title: "Double Scoop" },
    ],
  },
  {
    title: "Metal",
    links: [
      { title: "Yellow Gold" },
      { title: "White Gold" },
      { title: "Rose Gold" },
      { title: "Platinum" },
    ],
  },
  {
    title: "Sort",
    links: [
      { title: "Recommended" },
      { title: "Best Selling" },
      { title: "Newest" },
      { title: "Price: High to Low" },
      { title: "Price: Low to High" },
    ],
  },
];

export const braceletFilterLinks = [
  {
    title: "Shape",
    links: [{ title: "Round" }],
  },
  {
    title: "Collection",
    links: [{ title: "The OG" }],
  },
  {
    title: "Metal",
    links: [{ title: "Yellow Gold" }, { title: "White Gold" }],
  },
];

export const defaultDiamondData = [
  {
    Id: "8082766561519",
    Shape: "Round",
    ["Cut Grade"]: "Super Ideal",
    Clarity: "VS1-VS2",
    Carat: 2,
    ["Color"]: "F-G",
    Cost: 2400,
    ["Diamond ID"]: "8082766561519",
    VariantId: 44009758753007,
    ["GIA Number"]: "default",
  },
  {
    Id: "8082767872239",
    Shape: "Asscher",
    ["Cut Grade"]: "Super Ideal",
    Clarity: "VS1-VS2",
    Carat: 2,
    ["Color"]: "F-G",
    Cost: 2400,
    ["Diamond ID"]: "8082767872239",
    VariantId: 44009759736047,
    ["GIA Number"]: "default",
  },
  {
    Id: "8082767118575",
    Shape: "Cushion",
    ["Cut Grade"]: "Super Ideal",
    Clarity: "VS1-VS2",
    Carat: 2,
    ["Color"]: "F-G",
    Cost: 2400,
    ["Diamond ID"]: "8082767118575",
    VariantId: 44004479926511,
    ["GIA Number"]: "default",
  },
  {
    Id: "8082766856431",
    Shape: "Emerald",
    ["Cut Grade"]: "Super Ideal",
    Clarity: "VS1-VS2",
    Carat: 2,
    ["Color"]: "F-G",
    Cost: 2400,
    ["Diamond ID"]: "8082766856431",
    VariantId: 44009760293103,
    ["GIA Number"]: "default",
  },
  {
    Id: "8082767249647",
    Shape: "Marquise",
    ["Cut Grade"]: "Super Ideal",
    Clarity: "VS1-VS2",
    Carat: 2,
    ["Color"]: "F-G",
    Cost: 2400,
    ["Diamond ID"]: "8082767249647",
    VariantId: 44004480024815,
    ["GIA Number"]: "default",
  },
  {
    Id: "8082768822511",
    Shape: "Old European",
    ["Cut Grade"]: "Super Ideal",
    Clarity: "VS1-VS2",
    Carat: 2,
    ["Color"]: "F-G",
    Cost: 2400,
    ["Diamond ID"]: "8082768822511",
    VariantId: 44014568440047,
    ["GIA Number"]: "default",
  },
  {
    Id: "8082768953583",
    Shape: "Old Mine",
    ["Cut Grade"]: "Super Ideal",
    Clarity: "VS1-VS2",
    Carat: 2,
    ["Color"]: "F-G",
    Cost: 2400,
    ["Diamond ID"]: "8082768953583",
    VariantId: 44016087990511,
    ["GIA Number"]: "default",
  },
  {
    Id: "8080209477871",
    Shape: "Oval",
    ["Cut Grade"]: "Super Ideal",
    Clarity: "VS1-VS2",
    Carat: 2,
    ["Color"]: "F-G",
    Cost: 2400,
    ["Diamond ID"]: "8080209477871",
    VariantId: 44009760588015,
    ["GIA Number"]: "default",
  },
  {
    Id: "8082767479023",
    Shape: "Pear",
    ["Cut Grade"]: "Super Ideal",
    Clarity: "VS1-VS2",
    Carat: 2,
    ["Color"]: "F-G",
    Cost: 2400,
    ["Diamond ID"]: "8082767479023",
    VariantId: 44004480254191,
    ["GIA Number"]: "default",
  },
  {
    Id: "8082768658671",
    Shape: "Princess",
    ["Cut Grade"]: "Super Ideal",
    Clarity: "VS1-VS2",
    Carat: 2,
    ["Color"]: "F-G",
    Cost: 2400,
    ["Diamond ID"]: "8082768658671",
    VariantId: 44004483006703,
    ["GIA Number"]: "default",
  },
  {
    Id: "8082768494831",
    Shape: "Radiant",
    ["Cut Grade"]: "Super Ideal",
    Clarity: "VS1-VS2",
    Carat: 2,
    ["Color"]: "F-G",
    Cost: 2400,
    ["Diamond ID"]: "8082768494831",
    VariantId: 44016101425391,
    ["GIA Number"]: "default",
  },
  {
    Id: "8082768232687",
    Shape: "Rose Cut",
    ["Cut Grade"]: "Super Ideal",
    Clarity: "VS1-VS2",
    Carat: 2,
    ["Color"]: "F-G",
    Cost: 2400,
    ["Diamond ID"]: "8082768232687",
    VariantId: 44004481532143,
    ["GIA Number"]: "default",
  },
  {
    Id: "8082768003311",
    Shape: "Trillion",
    ["Cut Grade"]: "Super Ideal",
    Clarity: "VS1-VS2",
    Carat: 2,
    ["Color"]: "F-G",
    Cost: 2400,
    ["Diamond ID"]: "8082768003311",
    VariantId: 44004480614639,
    ["GIA Number"]: "default",
  },
];
